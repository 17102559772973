<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="my-card m-0">
                <b-table 
                striped
                small
                id="tableAccess"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="items"
                :fields="fields"
                @row-dblclicked="editForm"
                @row-clicked="clicked"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>
                <template #cell(is_active)="data">
                        <span v-if="data.item.is_active == true">
                            Активен
                        </span>
                        <span v-else>
                            Не активен
                        </span>
                </template>
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import tablePosition from '@/views/component/Table/tablePosition.vue'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['items'],
    components: {
        tablePosition,
    },
    data(){
        return {
            fields: [ 
        { key: 'checkbox', label: '', thStyle: {width: '10px'}},
        { key: 'id', label: 'Id', sortable: true, thStyle: {width: '20px'}},
        { key: 'name', label: 'Имя', sortable: true, thStyle: {width: '150px'}},
        { key: 'route', label: 'Раздел', sortable: true, thStyle: {width: '150px'}},
        { key: 'is_active', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
      ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(id){
            this.$emit('editForm', id.id)
        },
        refresh(){
            this.$emit('refresh')
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            cellSelect()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style> 